.page-module__6Tf22G__headline {
  border-top: 1px solid var(--wcom-c-blueMid);
  background: var(--wcom-c-blueMid);
  color: var(--wcom-c-white);
  margin-bottom: 0;
  padding: 10px;
  font-size: 24px;
  line-height: 32px;
}

.page-module__6Tf22G__subHeadline {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 26px;
}

.styles-module__HecW3q__cta {
  color: var(--wcom-c-whiteAlpha60);
  align-items: center;
  gap: 5px;
  font-size: 12px;
  display: flex;
}

.styles-module__HecW3q__wrapper {
  color: var(--wcom-c-almostBlack);
  background-color: #eff3f9;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.styles-module__HecW3q__headline {
  margin: 20px 20px 12px;
  font-size: 20px;
  line-height: 24px;
}

.styles-module__HecW3q__text {
  margin: 0 20px 12px;
  font-size: 12px;
}

.styles-module__HecW3q__currentWeatherBar {
  background-color: #3769b6;
  align-items: center;
  padding: 4px 20px;
  display: flex;
}

.styles-module__HecW3q__flexWrapper {
  display: flex;
}

.styles-module__HecW3q__currentWeatherData {
  color: var(--wcom-c-white);
}

.styles-module__HecW3q__temperature {
  font-size: 18px;
  font-weight: 600;
}

.styles-module__HecW3q__stepIndicator {
  gap: 4px;
  margin: 28px 20px 8px;
  display: flex;
}

.styles-module__HecW3q__stepIndicatorItem {
  background-color: #c4cad4;
  border-radius: 2px;
  width: 34px;
  height: 4px;
}

.styles-module__HecW3q__stepIndicatorItemActive {
  background-color: #8f97a3;
  border-radius: 2px;
  width: 34px;
  height: 4px;
}

.styles-module__HecW3q__buttonContainer {
  margin: 20px;
  display: flex;
}

.styles-module__HecW3q__backButton {
  flex: 1;
  background-color: #d9e3f0 !important;
  border: none !important;
}

.styles-module__HecW3q__backButton span[class^="icon-"] {
  transform: rotate(90deg);
}

.styles-module__HecW3q__stepWrapper {
  flex-grow: 1;
  overflow-y: scroll;
}

.styles-module__HecW3q__stepHeadlineContainer {
  background-color: #eff3f9;
  width: 100%;
  position: fixed;
}

.styles-module__HecW3q__stepHeadline {
  margin: 0 20px;
  font-size: 18px;
  font-weight: 600;
}

.styles-module__HecW3q__stepCurrent {
  margin: 0 20px;
}

.styles-module__HecW3q__options {
  flex-direction: column;
  gap: 1px;
  margin-top: 52px;
  display: flex;
}

.styles-module__HecW3q__option {
  background-color: var(--wcom-c-white);
  gap: 20px;
  padding: 12px 20px;
  font-size: 15px;
  display: flex;
}

.styles-module__HecW3q__option span[class="icon-precipitation-low"] span[class="path1"]:before {
  color: var(--wcom-c-almostBlack);
}

.styles-module__HecW3q__option span[class="icon-precipitation-low"] span[class="path2"]:before, .styles-module__HecW3q__option span[class="icon-precipitation-low"] span[class="path3"]:before {
  color: #c4cad4;
}

.styles-module__HecW3q__option span[class="icon-precipitation-medium"] span[class="path1"]:before, .styles-module__HecW3q__option span[class="icon-precipitation-medium"] span[class="path3"]:before {
  color: var(--wcom-c-almostBlack);
}

.styles-module__HecW3q__option span[class="icon-precipitation-medium"] span[class="path2"]:before {
  color: #c4cad4;
}

.styles-module__HecW3q__optionActive {
  background-color: #cee3fd;
}

.styles-module__HecW3q__optionText {
  flex-grow: 1;
}

.styles-module__HecW3q__radioButton {
  border: 3px solid #398ff9;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.styles-module__HecW3q__radioButton:before {
  content: "";
  background-color: #398ff9;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: none;
}

.styles-module__HecW3q__radioButtonActive:before {
  display: block;
}

.styles-module__Qr3rfa__link, .styles-module__Qr3rfa__video {
  border-top: 1px solid var(--wcom-c-white);
  background: linear-gradient(0deg, var(--wcom-c-blueGradient2), var(--wcom-c-blueGradient1) 50%, var(--wcom-c-blueGradient2));
  height: 36px;
  color: var(--wcom-c-white);
  align-items: center;
  padding: 10px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
}

.styles-module__Qr3rfa__link > span, .styles-module__Qr3rfa__video > span {
  margin-right: 10px;
}

.styles-module__Qr3rfa__video {
  height: auto;
  padding: 0;
  display: block;
}

.styles-module__Qr3rfa__headline {
  color: var(--wcom-c-white);
  margin-bottom: 0;
  padding: 15px 10px 10px;
  font-size: 15px;
}

.styles-module__Qr3rfa__videoWrapper {
  padding-bottom: 5px;
  position: relative;
}

.styles-module__Qr3rfa__videoWrapper img {
  display: block;
}

.styles-module__Qr3rfa__playIcon {
  font-size: 35px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.styles-module__XYrWqW__markdown p, .styles-module__XYrWqW__markdown li, .styles-module__XYrWqW__markdown ul, .styles-module__XYrWqW__markdown strong, .styles-module__XYrWqW__markdown b, .styles-module__XYrWqW__markdown i {
  font-size: 12px;
}

.styles-module__XYrWqW__markdown * {
  word-wrap: break-word;
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
}

.styles-module__XYrWqW__markdown ul, .styles-module__XYrWqW__markdown p {
  margin-bottom: 20px;
}

.styles-module__2OS9kq__title {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 26px;
}

.styles-module__2OS9kq__link {
  border-bottom: 1px solid var(--wcom-c-gray);
  width: 100%;
  color: var(--wcom-c-blueDark);
  flex: 1;
  font-size: 12px;
}

.styles-module__2OS9kq__adNotice {
  color: var(--wcom-c-grayAdNotice);
  text-transform: uppercase;
  font-size: 10px;
  position: absolute;
  bottom: -20px;
  right: 10px;
}

.styles-module__2OS9kq__wrapper {
  flex-direction: column;
  margin-bottom: 20px;
  display: flex;
  position: relative;
}

.styles-module__2OS9kq__textWrapper {
  align-items: center;
  height: 55px;
  display: flex;
}

.styles-module__2OS9kq__icon {
  margin-right: 20px;
  font-size: 15px;
}

.styles-module__2OS9kq__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.styles-module__U6RSUW__wrapper {
  background: var(--wcom-c-white);
  border-radius: 20px;
  width: 80px;
  height: 30px;
  position: relative;
}

@keyframes styles-module__U6RSUW__pulsate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.styles-module__U6RSUW__dot {
  background-color: var(--wcom-c-redDarker);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  animation: 1.5s ease-out infinite styles-module__U6RSUW__pulsate;
  position: absolute;
  top: 11px;
  right: 58px;
}

.styles-module__U6RSUW__ring {
  border: 2px solid var(--wcom-c-redDarker);
  border-radius: 30px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 7px;
  right: 54px;
}

.styles-module__U6RSUW__text {
  color: var(--wcom-c-redDarker);
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  left: 18px;
  transform: translate(10px, 5px);
}

:root {
  --wcom-font-size-mobile: 12px;
  --wcom-line-height-mobile: 16px;
}

.styles-module__iZr3ZG__headlineFontSize15 {
  font-size: 15px;
  line-height: 34px;
}

.styles-module__iZr3ZG__headlineFontSize20 {
  font-size: 20px;
  line-height: 30px;
}

.styles-module__iZr3ZG__headlineH3Snow {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  font-size: 18px;
  font-weight: 600;
}

.styles-module__iZr3ZG__locationsContent {
  font-size: 13px;
}

@media (min-width: 768px) {
  .styles-module__iZr3ZG__locationsContent {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
  }
}

.styles-module__iZr3ZG__locationInner {
  --wcom-border-color: var(--lightningcss-light, var(--wcom-c-outline-variant-light)) var(--lightningcss-dark, var(--wcom-c-outline-variant-dark));
  border-bottom: 1px solid var(--wcom-border-color);
  flex: 1;
  display: block;
  position: relative;
  overflow: hidden;
}

.styles-module__iZr3ZG__locationInner:first-child {
  margin-top: -6px;
}

.styles-module__iZr3ZG__locationInner:last-child {
  border-bottom: none;
}

.styles-module__iZr3ZG__locationInner:first-child > div {
  padding-top: 0;
}

.styles-module__iZr3ZG__locationInner:last-child > div {
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .styles-module__iZr3ZG__locationInner {
    flex: 0 0 calc(50% - 40px);
  }

  .styles-module__iZr3ZG__locationInner:nth-child(odd) {
    margin-right: 40px;
  }

  .styles-module__iZr3ZG__locationInner:first-child {
    margin-top: 0;
  }

  .styles-module__iZr3ZG__locationInner:last-child {
    border-bottom: 1px solid var(--wcom-border-color);
  }

  .styles-module__iZr3ZG__locationInner:first-child > div {
    padding-top: 5px;
  }

  .styles-module__iZr3ZG__locationInner:last-child > div {
    padding-bottom: 5px;
  }
}

.styles-module__iZr3ZG__locationA {
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
}

.styles-module__iZr3ZG__locationCnt {
  background: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  text-overflow: ellipsis;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 50px;
  padding: 5px 0;
  font-size: 13px;
  display: flex;
}

.styles-module__iZr3ZG__locationCnt span[class^="icon-"] {
  font-size: 10px;
  display: inline-block;
  transform: rotate(-90deg);
}

.styles-module__iZr3ZG__headline {
  font-size: 20px;
  line-height: 25px;
}

.styles-module__iZr3ZG__headline2 {
  font-size: 15px;
  line-height: 20px;
}

.styles-module__iZr3ZG__colCity {
  width: 150px;
}

.styles-module__iZr3ZG__colTemp {
  min-height: 21px;
}

.styles-module__iZr3ZG__colTempSeparator {
  padding: 0 3px;
}

.styles-module__iZr3ZG__colTempMin {
  font-size: 12px;
}

.styles-module__iZr3ZG__colText {
  flex: 2;
}

.styles-module__iZr3ZG__colTextBold {
  flex: 3;
  font-weight: 600;
}

.styles-module__iZr3ZG__colDistance {
  flex: 1;
  padding-left: 10px;
}

.styles-module__iZr3ZG__locationInnerButton {
  width: 100%;
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
}

.styles-module__iZr3ZG__description {
  font-size: 15px;
  line-height: 23px;
}

.forecast-module__ICxqPa__section2 {
  height: 60px;
  position: relative;
}

.forecast-module__ICxqPa__section3 {
  margin: 0 15px;
  padding: 10px 0 20px;
  position: relative;
}

.forecast-module__ICxqPa__section4 {
  margin: 0 15px;
  padding-bottom: 20px;
  position: relative;
}

.forecast-module__ICxqPa__section5 {
  margin: 0;
  padding: 0 0 10px;
  position: relative;
}

.forecast-module__ICxqPa__section6 {
  margin: 0 15px;
  position: relative;
}

.forecast-module__ICxqPa__section7 {
  margin: 0 15px;
  padding: 20px 0;
  position: relative;
}

.forecast-module__ICxqPa__section8 {
  margin: 0 15px;
  padding: 0;
  position: relative;
}

.forecast-module__ICxqPa__section9 {
  margin: 0 15px;
  padding: 10px 0 30px;
  position: relative;
}

.forecast-module__ICxqPa__section10 {
  padding: 10px 0 10px 10px;
  position: relative;
}

.forecast-module__ICxqPa__section11 {
  margin: 0 15px;
  padding: 10px 0 60px;
  position: relative;
}

.forecast-module__ICxqPa__section12 {
  margin: 0 15px;
  padding: 10px 0;
  position: relative;
}

.forecast-module__ICxqPa__section13 {
  padding: 20px 0 0;
  position: relative;
}

.forecast-module__ICxqPa__section14 {
  margin: 0 15px;
  padding: 20px 0 10px;
  position: relative;
}

.forecast-module__ICxqPa__section15 {
  margin: 0 15px;
  padding: 0 0 10px;
  position: relative;
}

.forecast-module__ICxqPa__section16 {
  margin: 0 15px;
  padding: 15px 0 0;
  position: relative;
}

.forecast-module__ICxqPa__section17 {
  margin-left: 15px;
  padding: 10px 0 20px;
  position: relative;
}

.forecast-module__ICxqPa__section18 {
  margin: 0 10px;
  padding: 30px 0;
  position: relative;
}

.forecast-module__ICxqPa__section19 {
  margin: 0;
  padding: 0 10px 10px;
  position: relative;
}

.forecast-module__ICxqPa__border {
  border-top: 1px solid var(--wcom-c-gray);
  margin: 0 10px;
}

.forecast-module__ICxqPa__border2 {
  border-top: 1px solid var(--wcom-c-gray);
  margin: 0 15px 10px;
}

.forecast-module__ICxqPa__border3 {
  border-top: 1px solid var(--wcom-c-gray);
  margin: 5px 0;
}

.forecast-module__ICxqPa__border4 {
  border-top: 1px solid var(--wcom-c-gray);
  margin: 0 15px;
}

.forecast-module__ICxqPa__h1 {
  border-top: 1px solid var(--wcom-c-blueMid);
  background: var(--wcom-c-blueMid);
  color: var(--wcom-c-white);
  margin-bottom: 0;
  padding: 10px;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

.forecast-module__ICxqPa__h2 {
  font-size: 20px;
}

.forecast-module__ICxqPa__h2Variant1 {
  margin: 0 0 16px;
  font-size: 20px;
  line-height: 26px;
}

.forecast-module__ICxqPa__h2Variant2 {
  font-size: 15px;
}

.forecast-module__ICxqPa__spacer {
  height: 40px;
}

.forecast-module__ICxqPa__wrapperAstronomy {
  margin-top: 20px;
}

:root {
  --wcom-font-size-mobile: 12px;
  --wcom-line-height-mobile: 16px;
}

.styles-module__ZNKCoG__divHeadline {
  margin-top: 0;
  margin-bottom: 12px;
  padding-right: 10px;
  font-weight: 400;
  line-height: 25px;
}

@media (min-width: 768px) {
  .styles-module__ZNKCoG__divHeadline {
    min-height: 25px;
  }
}

.styles-module__ZNKCoG__fontSize20 {
  font-size: 20px;
}

.styles-module__ZNKCoG__fontSize15 {
  font-size: 15px;
}

.styles-module__ZNKCoG__fontSize18Bold {
  font-size: 18px;
  font-weight: bold;
}

.styles-module__ZNKCoG__h3 {
  margin-bottom: 12px;
  font-size: 20px;
}

.styles-module__ZNKCoG__title {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
}

.styles-module__ZNKCoG__adNotice {
  z-index: 1;
  color: var(--wcom-c-white);
  text-transform: uppercase;
  font-size: 10px;
  position: absolute;
  top: -40px;
  right: 0;
}

.styles-module__ZNKCoG__borderLargeItems {
  border-top: 1px solid var(--wcom-c-gray);
  margin: 0 0 20px;
}

.styles-module__ZNKCoG__playlistWrapper {
  position: relative;
}

.styles-module__E8lp8q__wrapper {
  grid-gap: 1px;
  grid-template-rows: 40px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  display: grid;
}

.styles-module__E8lp8q__link {
  color: #fff;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.styles-module__E8lp8q__facebook {
  background-color: var(--wcom-c-socialsFacebook);
}

.styles-module__E8lp8q__twitter {
  background-color: var(--wcom-c-socialsTwitter);
}

.styles-module__E8lp8q__instagram {
  background-color: var(--wcom-c-socialsInstagram);
}

.styles-module__E8lp8q__whatsapp {
  background-color: var(--wcom-c-socialsWhatsapp);
}

.styles-module__xiOieG__wrapper {
  background: var(--lightningcss-light, var(--wcom-c-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-surface-variant-dark));
  border-radius: 16px;
  flex-direction: column;
  gap: 12px;
  margin: 0;
  padding: 16px;
  display: flex;
}

.styles-module__xiOieG__row {
  align-items: center;
  gap: 5px;
  display: flex;
}

.styles-module__xiOieG__headline {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.styles-module__xiOieG__wrapperPrec {
  background: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  border-radius: 16px;
  width: 100%;
  padding: 16px;
}

.styles-module__xiOieG__precText {
  margin-bottom: 10px;
}

.styles-module__KsRuDW__element {
  opacity: 0;
  z-index: var(--wcom-z-scrollToTop);
  background-color: var(--wcom-c-grayBlue);
  width: 40px;
  height: 40px;
  color: var(--wcom-c-white);
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  transition: all .5s;
  display: flex;
  position: fixed;
  bottom: 85px;
  right: 10px;
  transform: translateX(100px);
}

.styles-module__KsRuDW__element > span[class^="icon-"] {
  transform: rotate(180deg);
}

.styles-module__KsRuDW__show {
  opacity: .8;
  transform: translateX(0);
}

.styles-module__HEbECG__text {
  font-size: 15px;
  line-height: 20px;
}

.styles-module__HEbECG__name {
  font-weight: 600;
}

.styles-module__HEbECG__address {
  margin-bottom: 20px;
}

.styles-module__HEbECG__table {
  grid-gap: 5px 20px;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: 1fr 5fr;
  display: grid;
}

.styles-module__HEbECG__tableHeading {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
  text-align: right;
}

.styles-module__HEbECG__tableData {
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  word-break: break-all;
  font-weight: 600;
}

.styles-module__Mwb3TG__text {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
  padding: 20px 0 10px;
  font-size: 15px;
}

.styles-module__XKussG__wrapper {
  background: var(--lightningcss-light, var(--wcom-c-feature-blue-light)) var(--lightningcss-dark, var(--wcom-c-feature-blue-dark));
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  padding: 20px 10px;
  font-size: 12px;
}

.styles-module__XKussG__link {
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
}

.styles-module__XKussG__arrow {
  margin-right: 10px;
  display: inline-block;
  transform: rotate(180deg);
}

.styles-module__GSBMXa__table {
  grid-gap: 12px 20px;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.styles-module__GSBMXa__text {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}

.styles-module__GSBMXa__tableHeading {
  color: #60656c;
  text-align: right;
  font-weight: 400;
}

.styles-module__8XDiBa__table {
  grid-gap: 12px 20px;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.styles-module__8XDiBa__text {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}

.styles-module__8XDiBa__tableHeading {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
  text-align: right;
  font-weight: 400;
}

.styles-module__9bKYwa__wrapper {
  position: relative;
}

.styles-module__9bKYwa__liveLabelWrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}

.styles-module__9bKYwa__playButton {
  cursor: pointer;
  color: var(--wcom-c-white);
  font-size: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.styles-module__9bKYwa__button {
  width: 100% !important;
  display: block !important;
}

.styles-module__ieFz5q__openingInfo {
  background-color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-primary-secondary-light)) var(--lightningcss-dark, var(--wcom-c-on-primary-secondary-dark));
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 12px;
  display: inline-block;
}

.styles-module__ieFz5q__openingInfoIsOpen {
  background-color: var(--lightningcss-light, var(--wcom-c-success-container-light)) var(--lightningcss-dark, var(--wcom-c-success-container-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
}

.styles-module__0wwixW__ticket {
  --wcom-border-color: var(--lightningcss-light, var(--wcom-c-outline-light)) var(--lightningcss-dark, var(--wcom-c-outline-dark));
  --wcom-border-surface-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  border: 1px solid var(--wcom-border-color);
  background-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  border-radius: 20px;
  width: 100%;
  display: inline-block;
  position: relative;
}

.styles-module__0wwixW__ticket:before, .styles-module__0wwixW__ticket:after {
  border: 1px solid var(--wcom-border-color);
  background-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  content: "";
  width: 14px;
  height: 28px;
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  position: absolute;
  top: 47px;
}

.styles-module__0wwixW__ticket:before {
  border-left-color: var(--wcom-border-surface-color);
  border-radius: 0 28px 28px 0;
  left: -2px;
}

.styles-module__0wwixW__ticket:after {
  border-right-color: var(--wcom-border-surface-color);
  border-radius: 28px 0 0 28px;
  right: -2px;
}

.styles-module__0wwixW__headline {
  --wcom-border-color: var(--lightningcss-light, var(--wcom-c-outline-light)) var(--lightningcss-dark, var(--wcom-c-outline-dark));
  border-bottom: 1px dashed var(--wcom-border-color);
  padding: 20px;
  font-size: 18px;
  font-weight: 600;
}

.styles-module__0wwixW__table {
  grid-gap: 5px;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);
  padding: 20px;
  display: grid;
}

.styles-module__0wwixW__tableHeading {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
}

.styles-module__0wwixW__right {
  text-align: right;
}

.page-module__7_LqHW__headline {
  justify-content: space-between;
  margin: 10px 0;
  display: flex;
}

.page-module__7_LqHW__subHeadline1 {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 24px;
}

.page-module__7_LqHW__subHeadline2 {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  font-size: 15px;
  line-height: 20px;
}

.page-module__7_LqHW__sponsoringLabel {
  text-transform: uppercase;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 10px;
}

.page-module__7_LqHW__imageContainer {
  position: relative;
}

.page-module__7_LqHW__link {
  display: block;
  position: relative;
}

.page-module__7_LqHW__sponsoringLogo {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.styles-module__jFMXEW__link {
  vertical-align: bottom;
  white-space: normal;
  width: 85%;
  height: 200px;
  margin-right: 7px;
  font-size: 15px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.styles-module__jFMXEW__link:last-child {
  margin-right: 0;
}

@media (min-width: 550px) {
  .styles-module__jFMXEW__link {
    width: 45%;
  }
}

@media (min-width: 900px) {
  .styles-module__jFMXEW__link {
    width: 31%;
  }
}

.styles-module__jFMXEW__link2 {
  display: block;
  position: relative;
}

.styles-module__jFMXEW__link3 {
  color: var(--wcom-c-almostBlack);
}

.styles-module__jFMXEW__link4 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.styles-module__jFMXEW__linkWithMargin {
  margin-bottom: 20px;
  display: block;
  position: relative;
}

.styles-module__jFMXEW__linkFlexBox {
  margin-bottom: 10px;
  display: flex;
  position: relative;
}

.styles-module__jFMXEW__linkWithBorder {
  border-top: 1px solid var(--wcom-c-gray);
  min-height: 200px;
  display: block;
}

.styles-module__jFMXEW__linkWrapper {
  width: 50%;
}

.styles-module__jFMXEW__adNotice {
  color: var(--wcom-c-grayAdNotice);
  text-transform: uppercase;
  font-size: 10px;
  position: absolute;
  top: -20px;
  right: 10px;
}

.styles-module__jFMXEW__adNotice2 {
  color: var(--wcom-c-white);
  text-transform: uppercase;
  font-size: 10px;
  position: absolute;
  right: 10px;
}

.styles-module__jFMXEW__metaData {
  color: var(--wcom-c-blueDark);
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 16px;
}

.styles-module__jFMXEW__goTo, .styles-module__jFMXEW__goTo2 {
  color: var(--wcom-c-blueDark);
  margin-top: 20px;
  font-size: 12px;
}

.styles-module__jFMXEW__goToIcon {
  vertical-align: bottom;
  margin-left: 12px;
  font-size: 15px;
}

.styles-module__jFMXEW__goTo2 {
  font-size: 15px;
}

.styles-module__jFMXEW__leftColumn {
  flex: 3;
  position: relative;
}

.styles-module__jFMXEW__leftColumn img {
  max-width: calc(42.8571vw - 20px);
}

.styles-module__jFMXEW__rightColumn {
  color: var(--wcom-c-blueDark);
  flex: 4;
  padding: 0 15px;
  font-size: 12px;
}

.styles-module__jFMXEW__contentVisibilityAuto {
  content-visibility: auto;
}

.styles-module__jFMXEW__contentVisibilityVisible {
  content-visibility: visible;
}

.styles-module__jFMXEW__imageWrapper {
  position: relative;
}

.styles-module__jFMXEW__imageWrapper img {
  display: block;
}

.styles-module__jFMXEW__livecamLogo2 {
  background-color: var(--wcom-c-grayDark);
  width: 42px;
  height: 42px;
  color: var(--wcom-c-white);
  padding: 10px;
  font-size: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

.styles-module__jFMXEW__livecamLogo3 {
  background-color: var(--wcom-c-grayDark);
  width: 32px;
  height: 32px;
  color: var(--wcom-c-white);
  justify-content: center;
  align-items: center;
  font-size: 16px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.styles-module__jFMXEW__playButton {
  z-index: 1;
  border: unset;
  background-color: unset;
  color: var(--wcom-c-white);
  font-size: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.styles-module__jFMXEW__playButtonSmall {
  font-size: 35px;
}

.styles-module__jFMXEW__title {
  color: var(--wcom-c-blueDark);
  margin: 20px 0;
  font-size: 16px;
}

.styles-module__jFMXEW__title2 {
  color: var(--wcom-c-blueDark);
  font-size: 15px;
}

.styles-module__jFMXEW__title3 {
  color: var(--wcom-c-blueDark);
  margin: 15px 0 5px;
  font-size: 15px;
}

.styles-module__jFMXEW__subtitle {
  color: var(--wcom-c-blueDark);
  text-transform: uppercase;
  font-size: 12px;
}

.styles-module__jFMXEW__description {
  color: var(--wcom-c-grayBlue);
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 12px;
  line-height: 16px;
}

.styles-module__jFMXEW__description2, .styles-module__jFMXEW__description3 {
  color: var(--wcom-c-grayBlue);
  margin: 5px 0;
  font-size: 12px;
  line-height: 16px;
}

.styles-module__jFMXEW__description3 {
  color: var(--wcom-c-blueDark);
}

.styles-module__jFMXEW__text, .styles-module__jFMXEW__additionalText {
  margin-bottom: 0;
}

.styles-module__jFMXEW__additionalText {
  color: var(--wcom-c-blueDark);
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.styles-module__jFMXEW__additionalText > [class^="icon"] {
  margin-left: 5px;
  font-size: 18px;
}

.styles-module__jFMXEW__buttonWithMargin {
  all: unset;
  margin-bottom: 20px;
  display: block;
  position: relative;
}

.styles-module__jFMXEW__h3 {
  color: var(--wcom-c-blueDark);
  margin: 15px 0 10px 10px;
}

.styles-module__g6YuNa__title {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 27px;
}

.styles-module__g6YuNa__form {
  border: 1px solid #d7d7d7;
  border-radius: 1.25em;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 41px;
  padding: 0 .625em;
  display: flex;
  position: relative;
}

.styles-module__g6YuNa__isOpen {
  border-radius: 1.25em 1.25em 0 0;
}

.styles-module__g6YuNa__suggestInput {
  border: none;
  outline: 3px solid #0000;
  flex: 1;
}

.styles-module__g6YuNa__suggestInput ::placeholder {
  color: var(--wcom-c-grayMid);
  font-weight: 400;
}

.styles-module__g6YuNa__magnifier {
  color: var(--wcom-c-blueDark);
  font-size: 12px;
}

.styles-module__g6YuNa__suggestList {
  z-index: var(--wcom-z-autosuggest);
  border: 1px solid var(--wcom-c-grayLight);
  background-color: var(--wcom-c-white);
  border-top: none;
  width: 100%;
  margin: 0;
  padding: .625em;
  list-style-type: none;
  position: absolute;
}

.styles-module__g6YuNa__suggestItem {
  padding: 5px;
  display: block;
}

.styles-module__g6YuNa__tagSearchWrapper {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.styles-module__g6YuNa__sliderContent {
  gap: 8px;
  display: flex;
}

.styles-module__g6YuNa__tag {
  background-color: var(--wcom-c-white);
  height: 48px;
  color: var(--wcom-c-almostBlack);
  white-space: nowrap;
  border: 1px solid #c7cbd1;
  border-radius: 8px;
  align-items: center;
  gap: 4px;
  padding: 12px;
  display: flex;
}

.styles-module__g6YuNa__activeTag {
  border: 1px solid var(--wcom-c-blueDark);
  background-color: #cce3ff;
}

.styles-module__g6YuNa__resultList {
  margin-top: 20px;
  position: relative;
}

.styles-module__g6YuNa__resultList:after {
  pointer-events: none;
  content: "";
  background: linear-gradient(0deg, #fff 0%, #fff0 100%);
  width: 100%;
  height: 180px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.styles-module__g6YuNa__showGradient:after {
  display: block;
}

.styles-module__g6YuNa__loadMoreButton {
  margin-top: 10px;
}

.styles-module__g6YuNa__loadMoreButton span[class^="icon"] {
  margin-left: 10px;
  font-size: 8px;
}

.styles-module__g6YuNa__errorMessage {
  margin-top: 20px;
}

.styles-module__g6YuNa__listItemButton {
  all: unset;
}

.arrow-module__iG2WmG__arrow {
  text-align: left;
  width: 60px;
  height: 100%;
  padding-left: 10px;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.arrow-module__iG2WmG__arrowTopLocations {
  z-index: var(--wcom-z-header-top-locations);
  align-items: center;
  display: flex;
}

.arrow-module__iG2WmG__arrowTopLocations span[class="icon-dropdown"] {
  height: 20px;
  font-size: 10px;
  display: block;
}

.arrow-module__iG2WmG__arrowMiaMap {
  z-index: var(--wcom-z-header-top-locations);
  color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  align-items: center;
  display: flex;
}

.arrow-module__iG2WmG__arrowMiaMap span[class="icon-dropdown"] {
  height: 20px;
  font-size: 10px;
  display: block;
}

.arrow-module__iG2WmG__arrowForecastNavigation {
  z-index: var(--wcom-z-legacyForecastNavigationArrow);
  color: var(--wcom-c-blueDark);
  line-height: 62px;
}

.arrow-module__iG2WmG__arrowForecastNavigation span[class="icon-dropdown"] {
  height: 60px;
  font-size: 12px;
  display: block;
}

.arrow-module__iG2WmG__leftTopLocations {
  background: linear-gradient(90deg, var(--wcom-c-blueMid), transparent);
  left: 0;
}

.arrow-module__iG2WmG__leftTopLocations span[class="icon-dropdown"] {
  transform: rotate(90deg);
}

.arrow-module__iG2WmG__rightTopLocations {
  background: linear-gradient(90deg, transparent, var(--wcom-c-blueMid));
  justify-content: right;
  right: 0;
}

.arrow-module__iG2WmG__rightTopLocations span[class="icon-dropdown"] {
  transform: rotate(270deg);
}

.arrow-module__iG2WmG__leftForecastNavigation {
  background: linear-gradient(90deg, var(--wcom-c-white), var(--wcom-c-whiteAlpha70), transparent);
  left: -10px;
  right: auto;
}

.arrow-module__iG2WmG__leftForecastNavigation span[class="icon-dropdown"] {
  transform: translateX(-50%)translateY(25%)rotate(90deg);
}

.arrow-module__iG2WmG__rightForecastNavigation {
  background: linear-gradient(90deg, transparent, var(--wcom-c-whiteAlpha70), var(--wcom-c-white));
  left: auto;
  right: 0;
}

.arrow-module__iG2WmG__rightForecastNavigation span[class="icon-dropdown"] {
  transform: translateX(50%)translateY(-25%)rotate(270deg);
}

.arrow-module__iG2WmG__leftMiaMap {
  background: linear-gradient(90deg, var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark)), transparent);
  left: 0;
}

.arrow-module__iG2WmG__leftMiaMap span[class="icon-dropdown"] {
  transform: rotate(90deg);
}

.arrow-module__iG2WmG__rightMiaMap {
  background: linear-gradient(90deg, transparent, var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark)));
  justify-content: right;
  right: 0;
}

.arrow-module__iG2WmG__rightMiaMap span[class="icon-dropdown"] {
  transform: rotate(270deg);
}

.arrow-module__iG2WmG__hidden {
  display: none;
}

.styles-module__UCEkBG__wrapper {
  position: relative;
}

.styles-module__UCEkBG__slider {
  white-space: nowrap;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  scroll-snap-stop: always;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  display: block;
  position: relative;
  overflow: scroll hidden;
}

.styles-module__UCEkBG__slider::-webkit-scrollbar {
  display: none;
}

.styles-module__UCEkBG__slider [class*="title"] {
  white-space: normal;
  position: sticky;
  top: 0;
  left: 0;
}

.styles-module__1Ww_sW__section {
  margin: 0 10px;
  padding: 30px 0;
  position: relative;
}

.styles-module__1Ww_sW__link {
  border-top: 1px solid var(--wcom-c-gray);
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  display: flex;
}

.styles-module__DBl3uq__title {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 28px;
}

.styles-module__DBl3uq__link {
  color: var(--wcom-c-blueDark);
  align-items: center;
  font-size: 12px;
  display: flex;
}

.styles-module__DBl3uq__link > [class^="icon"] {
  margin-left: 5px;
  font-size: 16px;
}

.page-module__RoKzaq__spacer {
  margin-bottom: 20px;
}

.page-module__RoKzaq__specialAdLabel {
  color: var(--wcom-c-white);
  text-transform: uppercase;
  font-size: 8px;
  position: absolute;
  top: 95px;
  right: 10px;
}

.page-module__RoKzaq__specialCityLabel {
  margin: 10px 0;
  font-size: 16px;
}

.page-module__RoKzaq__weatherWrapper {
  border-top: 1px solid var(--wcom-c-gray);
  border-bottom: 1px solid var(--wcom-c-gray);
  padding-top: 6px;
}

.page-module__RoKzaq__headline {
  margin: 10px 0 20px;
  font-size: 15px;
  line-height: 20px;
}

.page-module__RoKzaq__playerWrapper {
  aspect-ratio: auto 16 / 9;
  max-width: calc(100vw - 20px);
}

:root {
  --wcom-font-size-mobile: 12px;
  --wcom-line-height-mobile: 16px;
}

.item-module__RV7kFG__wrapper {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  min-height: 275px;
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  border-radius: 16px;
  margin: 0 10px 10px;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .item-module__RV7kFG__wrapper {
    box-sizing: border-box;
    margin: unset;
    flex: 0 0 calc(50% - 5px);
  }
}

.item-module__RV7kFG__link {
  width: 100%;
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  display: block;
}

.item-module__RV7kFG__infoBadges {
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
}

.item-module__RV7kFG__distanceBadge {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-surface-variant-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  border-radius: 8px;
  gap: 4px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
}

.item-module__RV7kFG__distanceBadgeIcon {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
}

.item-module__RV7kFG__information {
  padding: 10px;
}

.item-module__RV7kFG__container {
  justify-content: space-between;
  margin-bottom: 10px;
  display: flex;
}

.item-module__RV7kFG__left {
  flex: 4;
}

.item-module__RV7kFG__right {
  flex: 1;
  display: flex;
}

.item-module__RV7kFG__headline {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.item-module__RV7kFG__subHeadline {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 16px;
}

.item-module__RV7kFG__temperatureLow {
  font-size: 18px;
  line-height: 22px;
}

.item-module__RV7kFG__temperatureHigh {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.item-module__RV7kFG__informationRow {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-surface-variant-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 5px;
  font-size: 15px;
  display: flex;
}

.item-module__RV7kFG__informationText {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.item-module__RV7kFG__informationText span[class^="snow-icon-"] {
  margin-right: 5px;
}

.item-module__RV7kFG__informationTextBold {
  width: -moz-fit-content;
  width: fit-content;
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  white-space: nowrap;
  font-weight: 600;
}

.item-module__RV7kFG__slopes {
  white-space: nowrap;
  justify-content: right;
  align-items: center;
  width: 100%;
  font-weight: 600;
  display: flex;
}

.item-module__RV7kFG__slopeLengthIndicator {
  border-radius: 2px;
  width: 60%;
  height: 4px;
  margin-right: 5px;
  display: flex;
  overflow: hidden;
}

.item-module__RV7kFG__slope {
  height: 4px;
}

.item-module__RV7kFG__slopeColorRed {
  background-color: var(--lightningcss-light, var(--wcom-c-extreme-container-light)) var(--lightningcss-dark, var(--wcom-c-extreme-container-dark));
}

.item-module__RV7kFG__slopeColorBlue {
  background-color: var(--lightningcss-light, var(--wcom-c-secondary-light)) var(--lightningcss-dark, var(--wcom-c-secondary-dark));
}

.item-module__RV7kFG__slopeColorBlack {
  background-color: var(--lightningcss-light, var(--wcom-c-on-primary-secondary-dark)) var(--lightningcss-dark, var(--wcom-c-on-primary-secondary-dark));
}

.item-module__RV7kFG__reviewContainer {
  gap: 8px;
  padding: 0 10px 10px;
  display: flex;
}

.item-module__RV7kFG__reviewItem {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-surface-variant-dark));
  width: 40px;
  height: 40px;
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.item-module__RV7kFG__reviewItem [class^="snow-icon-"] {
  font-size: 24px;
}

.item-module__RV7kFG__textSeparator {
  margin: 0 3px;
}

.styles-module__HIn6tW__reviewItemContainer {
  margin-right: 3px;
  display: inline-block;
}

.styles-module__HIn6tW__reviewItemContainer:last-child {
  margin-right: 0;
}

.styles-module__HIn6tW__reviewItem {
  background-color: var(--lightningcss-light, var(--wcom-c-secondary-container-light)) var(--lightningcss-dark, var(--wcom-c-secondary-container-dark));
  min-width: 64px;
  height: 64px;
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  font-size: 12px;
  display: flex;
}

.styles-module__HIn6tW__reviewItemActive {
  background-color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-primary-secondary-light)) var(--lightningcss-dark, var(--wcom-c-on-primary-secondary-dark));
}

.styles-module__HIn6tW__reviewText {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
}

.styles-module__HIn6tW__reviewTextActive {
  color: var(--lightningcss-light, var(--wcom-c-on-primary-secondary-light)) var(--lightningcss-dark, var(--wcom-c-on-primary-secondary-dark));
}

.styles-module__HIn6tW__icon {
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 20px;
}

.styles-module__HIn6tW__text {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-surface-variant-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  white-space: break-spaces;
  border-radius: 12px;
  margin-top: 5px;
  padding: 10px;
}

.styles-module__HIn6tW__list {
  margin: 0;
  padding: 0 20px;
}

.styles-module__HIn6tW__hidden {
  display: none;
}

.styles-module__gJoccq__link {
  background: var(--lightningcss-light, var(--wcom-c-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-surface-variant-dark));
  border-radius: 20px;
  margin-bottom: 10px;
  display: block;
  position: relative;
}

.styles-module__gJoccq__adNotice {
  color: var(--lightningcss-light, var(--wcom-c-on-primary-secondary-light)) var(--lightningcss-dark, var(--wcom-c-on-primary-secondary-light));
  text-transform: uppercase;
  font-size: 10px;
  position: absolute;
  top: -20px;
  right: 10px;
}

.styles-module__gJoccq__imageWrapper {
  position: relative;
}

.styles-module__gJoccq__imageWrapper img {
  border-radius: 20px 20px 2px 2px;
  display: block;
}

.styles-module__gJoccq__titleWrapper {
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  background: #27313f66;
  align-items: center;
  height: 56px;
  padding: 0 10px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.styles-module__gJoccq__title {
  color: var(--lightningcss-light, var(--wcom-c-on-primary-secondary-light)) var(--lightningcss-dark, var(--wcom-c-on-primary-secondary-light));
  font-size: 15px;
}

.styles-module__gJoccq__content {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  margin: 5px 0;
  padding: 10px;
  font-size: 15px;
  line-height: 24px;
}

.styles-module__gJoccq__goTo {
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  text-align: right;
  font-weight: 600;
}

.styles-module__gJoccq__goToIcon {
  vertical-align: bottom;
  margin-left: .75em;
  font-size: 15px;
}

.page-module__mpCxRa__border {
  border-top: 1px solid var(--wcom-c-gray);
  margin: 0 10px;
}

.page-module__mpCxRa__fontSize20 {
  font-size: 20px;
}

.page-module__mpCxRa__section, .page-module__mpCxRa__sectionSeoText, .page-module__mpCxRa__sectionCityWeather {
  content-visibility: auto;
  margin: 0 10px;
  padding: 30px 0;
  position: relative;
}

.page-module__mpCxRa__sectionPlaylist {
  margin: 0;
  padding: 30px 0 30px 10px;
}

.page-module__mpCxRa__sectionMiamap {
  margin: 0 0 0 10px;
  padding: 10px 0;
}

.page-module__mpCxRa__sectionMiamapText {
  content-visibility: auto;
  border-top: 1px solid var(--wcom-c-gray);
  margin: 0 10px;
  padding: 10px 0;
}

.page-module__mpCxRa__sectionSeoText, .page-module__mpCxRa__sectionCityWeather {
  padding: 30px 0 10px;
}

.page-module__mpCxRa__text {
  font-size: 15px;
  line-height: 23px;
}

.styles-module__YAv8JG__wrapper {
  flex-direction: column;
  min-height: 480px;
  display: flex;
}

.styles-module__YAv8JG__innerWrapper {
  height: 240px;
  margin-bottom: 15px;
  margin-left: 0;
  padding-left: 0;
}

.styles-module__YAv8JG__chart {
  height: 220px;
}

.styles-module__YAv8JG__legend {
  justify-content: space-evenly;
  font-size: 12px;
  display: flex;
}

.styles-module__YAv8JG__item {
  align-items: center;
  display: flex;
}

.styles-module__YAv8JG__orangeIndicator {
  background-color: var(--wcom-c-orange);
  width: 20px;
  height: 10px;
  margin-right: 5px;
}

.styles-module__YAv8JG__blueDarkIndicator {
  background-color: var(--wcom-c-blueDark);
  width: 20px;
  height: 10px;
  margin-right: 5px;
}

.styles-module__YAv8JG__blueMidIndicator {
  background-color: var(--wcom-c-blueMid);
  width: 20px;
  height: 10px;
  margin-right: 5px;
}

.styles-module__YAv8JG__orangeLightIndicator {
  background-color: var(--wcom-c-orangeLight);
  width: 20px;
  height: 10px;
  margin-right: 5px;
}

.page-module__efBllW__headerHeadline {
  border-top: 1px solid var(--wcom-c-blueMid);
  background: var(--wcom-c-blueMid);
  color: var(--wcom-c-white);
  margin-bottom: 0;
  padding: 10px;
}

.styles-module__LsJbMa__iconYellow {
  color: var(--wcom-c-yellow);
  margin-right: 5px;
}

.styles-module__LsJbMa__sunHours {
  margin-bottom: 10px;
  font-size: 12px;
}

.page-module__VQqF4q__goBack {
  background: var(--wcom-c-blueMid);
  color: var(--wcom-c-white);
  padding: 20px 10px;
  font-size: 12px;
  display: block;
}

.page-module__VQqF4q__goBackArrow:before {
  margin-right: 10px;
  display: inline-block;
  transform: rotate(180deg);
}

.styles-module__F5LUhW__timespan {
  font-size: 16px;
  font-weight: 600;
}

.styles-module__F5LUhW__calendar {
  justify-content: space-between;
  gap: 2px;
  margin-top: 10px;
  font-size: 12px;
  display: flex;
}

.styles-module__F5LUhW__month {
  text-align: center;
  width: 100%;
}

.styles-module__F5LUhW__bubble {
  background-color: var(--lightningcss-light, var(--wcom-c-outline-variant-light)) var(--lightningcss-dark, var(--wcom-c-outline-variant-dark));
  border-radius: 2px;
  height: 8px;
}

.styles-module__F5LUhW__bubbleIsOpen {
  background-color: var(--lightningcss-light, var(--wcom-c-success-light)) var(--lightningcss-dark, var(--wcom-c-success-dark));
}

.styles-module__AGzxHa__wrapper {
  gap: 5px;
  margin-bottom: 20px;
  display: flex;
}

.styles-module__AGzxHa__weatherItem {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  min-width: 108px;
  height: 100%;
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  display: flex;
}

.styles-module__AGzxHa__date {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
  text-align: center;
}

.styles-module__AGzxHa__day {
  margin-bottom: 5px;
  font-weight: 600;
}

.styles-module__AGzxHa__temperature {
  font-size: 15px;
  font-weight: 600;
}

.styles-module__AGzxHa__freshSnow {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
}

.styles-module__AGzxHa__freshSnow span[class^="icon-"] {
  color: var(--lightningcss-light, var(--wcom-c-outline-light)) var(--lightningcss-dark, var(--wcom-c-outline-dark));
  margin-right: 5px;
}

.styles-module__AGzxHa__weatherItemSeeMore {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  text-align: center;
  white-space: normal;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}

.styles-module__AGzxHa__seeMoreIcon {
  background-color: var(--lightningcss-light, var(--wcom-c-secondary-light)) var(--lightningcss-dark, var(--wcom-c-secondary-dark));
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-bottom: 10px;
  font-size: 10px;
  display: flex;
}

.styles-module__AGzxHa__seeMoreIcon:before {
  transform: rotate(270deg);
}

.styles-module__AGzxHa__linkWrapper {
  justify-content: center;
  margin: 0 10px;
  display: flex;
}

.styles-module__I-zBvG__wrapper {
  max-width: 500px;
  position: relative;
}

.styles-module__I-zBvG__liftCountWrapper {
  grid-gap: 8px;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  position: absolute;
  bottom: 20px;
  left: 10px;
  right: 10px;
}

.styles-module__I-zBvG__bubble {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  border-radius: 8px;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;
  padding: 4px 8px;
  display: flex;
}

.styles-module__I-zBvG__bubbleTotal {
  position: absolute;
  top: 20px;
  left: 10px;
}

.styles-module__I-zBvG__bubbleInner {
  align-items: baseline;
  display: flex;
}

.styles-module__I-zBvG__icon {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-light));
  width: 32px;
  height: 32px;
  color: var(--lightningcss-light, var(--wcom-c-primary-container-light)) var(--lightningcss-dark, var(--wcom-c-primary-container-dark));
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-size: 26px;
  display: flex;
}

.styles-module__I-zBvG__count {
  font-size: 16px;
  font-weight: 600;
}

.styles-module__I-zBvG__text {
  margin-left: 4px;
  font-size: 12px;
}

.styles-module__wgPqtW__link {
  display: inline-block;
}

.styles-module__wgPqtW__item {
  margin-right: 10px;
  position: relative;
}

.styles-module__wgPqtW__styledImageTeaser {
  border-radius: 20px;
}

.styles-module__wgPqtW__zoom {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  width: 48px;
  height: 48px;
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(90deg);
}

.styles-module__GZELfa__wrapper {
  background: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  border-radius: 20px;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin: 0 10px 24px;
  padding: 12px 20px;
  font-size: 12px;
  display: flex;
}

.styles-module__GZELfa__totalLabel {
  text-align: center;
  margin-bottom: 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.styles-module__GZELfa__bar {
  height: 8px;
}

.styles-module__GZELfa__barBlue {
  --wcom-border-color: var(--lightningcss-light, var(--wcom-c-on-surface-container-dark)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  border: 1px solid var(--wcom-border-color);
  background-color: var(--lightningcss-light, var(--wcom-c-secondary-light)) var(--lightningcss-dark, var(--wcom-c-secondary-dark));
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.styles-module__GZELfa__barRed {
  --wcom-border-color: var(--lightningcss-light, var(--wcom-c-on-surface-container-dark)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  border: 1px solid var(--wcom-border-color);
  background-color: var(--lightningcss-light, var(--wcom-c-extreme-container-light)) var(--lightningcss-dark, var(--wcom-c-extreme-container-dark));
}

.styles-module__GZELfa__barBlack {
  --wcom-border-color: var(--lightningcss-light, var(--wcom-c-on-surface-container-dark)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  border: 1px solid var(--wcom-border-color);
  background-color: var(--lightningcss-light, var(--wcom-c-on-primary-secondary-dark)) var(--lightningcss-dark, var(--wcom-c-on-primary-secondary-dark));
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.styles-module__GZELfa__slopeGrid {
  grid-gap: 5px;
  white-space: nowrap;
  border-radius: 2px;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: 1fr 1fr 8fr;
  align-items: center;
  width: 100%;
  display: grid;
  overflow: hidden;
}

.styles-module__GZELfa__right {
  text-align: right;
}

.styles-module__vL0l2a__legend {
  display: flex;
}

.styles-module__vL0l2a__legendItem {
  margin-right: 20px;
  display: flex;
}

.styles-module__vL0l2a__legendSquare {
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.styles-module__vL0l2a__blue {
  background-color: var(--lightningcss-light, var(--wcom-c-secondary-light)) var(--lightningcss-dark, var(--wcom-c-secondary-dark));
}

.styles-module__vL0l2a__red {
  background-color: var(--lightningcss-light, var(--wcom-c-extreme-container-light)) var(--lightningcss-dark, var(--wcom-c-extreme-container-dark));
}

.styles-module__vL0l2a__black {
  --wcom-border-color: var(--lightningcss-light, var(--wcom-c-on-surface-container-dark)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  border: 1px solid var(--wcom-border-color);
  background-color: var(--lightningcss-light, var(--wcom-c-on-primary-secondary-dark)) var(--lightningcss-dark, var(--wcom-c-on-primary-secondary-dark));
}

.styles-module__vL0l2a__skiroute {
  background-color: var(--lightningcss-light, var(--wcom-c-extreme-container-light)) var(--lightningcss-dark, var(--wcom-c-extreme-container-dark));
  transform: rotate(45deg);
}

.styles-module__vL0l2a__skiroute:before {
  content: "R";
  font-size: 12px;
  transform: rotate(-45deg);
}

.styles-module__vL0l2a__title {
  margin: 20px 0;
  font-weight: 600;
}

.styles-module__vL0l2a__list {
  max-height: 185px;
  margin: 0;
  padding-left: 3px;
  position: relative;
  overflow: hidden;
}

.styles-module__vL0l2a__open {
  max-height: unset;
}

.styles-module__vL0l2a__listItem {
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  display: flex;
}

.styles-module__vL0l2a__listItem:last-child {
  margin-bottom: 0;
}

.styles-module__vL0l2a__emptyTypeIndicator, .styles-module__vL0l2a__slopeTypeIndicator {
  justify-content: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.styles-module__vL0l2a__slopeTypeIndicator {
  border-radius: 4px;
}

.styles-module__vL0l2a__liftTypeIcon {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-light));
  color: var(--lightningcss-light, var(--wcom-c-primary-container-light)) var(--lightningcss-dark, var(--wcom-c-primary-container-dark));
  font-size: 24px;
}

.styles-module__vL0l2a__fader {
  background: linear-gradient(180deg, var(--lightningcss-light, #0000) var(--lightningcss-dark, #14181f00) 0%, var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark))100%);
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
}

.styles-module__vL0l2a__button {
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
}

.styles-module__vL0l2a__arrow {
  margin-top: 0;
  margin-left: 4px;
  font-size: 8px;
}

.styles-module__vL0l2a__arrowOpen {
  transform: rotate(180deg);
}

.styles-module__vL0l2a__fallbackWrapper {
  text-align: center;
  margin: 20px 0 0;
}

.styles-module__lCVbtG__imageWrapper {
  max-width: 500px;
  position: relative;
}

.styles-module__lCVbtG__label {
  background: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  white-space: nowrap;
  border-radius: 8px;
  align-items: baseline;
  gap: 8px;
  padding: 4px 8px;
  display: inline-flex;
  position: absolute;
  top: 20px;
  left: 10px;
}

.styles-module__lCVbtG__label span:first-child {
  font-size: 16px;
  font-weight: 600;
}

.styles-module__lCVbtG__label span:last-child {
  font-size: 12px;
}

.styles-module__lCVbtG__slopesWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.styles-module__lCVbtG__linkWrapper {
  justify-content: center;
  margin: 0 10px;
  padding-bottom: 20px;
  display: flex;
}

.styles-module__lCVbtG__link {
  color: var(--wcom-c-almostBlack);
}

.styles-module__lCVbtG__navigateButton {
  width: 100%;
  display: block;
}

.styles-module__X7sagW__tab {
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 44px;
  padding: 14px 20px;
  display: flex;
}

.styles-module__X7sagW__snowPrimary {
  background: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-primary-secondary-light)) var(--lightningcss-dark, var(--wcom-c-on-primary-secondary-dark));
}

.styles-module__X7sagW__snowSecondary {
  background: var(--lightningcss-light, var(--wcom-c-secondary-container-light)) var(--lightningcss-dark, var(--wcom-c-secondary-container-dark));
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
}

.styles-module__HUEMaG__wrapper {
  gap: 4px;
  padding: 20px 10px;
  display: flex;
}

